$primary-color: #FF0806;
$second-color: #020747;
$third-color: #222;

$rgb-primary-color: 255, 8, 6; //--primary-color *
$rgb-second-color: 2, 7, 71; //--second-color *
$rgb-third-color: 34, 34, 34; //--third-color *

@import "../../../src/styles/vars.scss";

@import "../../../src/styles/styles.scss";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Rethink+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

body.webapp-site {
	--font-title: 'Rethink Sans', sans-serif;
    --font-subtext: 'Open Sans', sans-serif;
    --font-text: 'Open Sans', sans-serif;
	background-color: #f6f9f9;

    // General
    .dropdown_menu_cy-item {
        .dropdown_menu_cy-action {
            font-family: var(--font-title);
        }
    }

    .custom__card-1 {
        padding: 1rem;
        border: 1px solid #ddd;
        border-radius: 10px;
        .content__img {
            width: 100%;
            display: block;
            border-radius: 8px;
            background-color: rgba($third-color, 0.2);
            &.default__ctt-image {
                min-height: 210px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    display: block;
                    margin: auto;
                    border-radius: 0;
                }
            }
            img {
                width: 100%;
                display: block;
                border-radius: 8px;
            }
        }
        .content__desc {
            padding: 1rem 0;
            .subtext__card {
                font-size: 14px;
                font-weight: 600;
                line-height: normal;
                margin: 0;
                margin-bottom: 8px;
            }
            .title__card {
                font-size: 20px;
                font-weight: 600;
                line-height: normal;
                margin: 0;
                margin-bottom: 10px;
                color: var(--primary-color);
            }
            .text__card {
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }
        }
        .content__price {
            color: var(--second-color);
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.75rem;
        }
        .content__btns {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            .btn_buy {
                border: 1px solid #D0D5DD;
                box-shadow: 0 1px 2px #1018280d;
                background-color: transparent;
            }
        }
    }

    .custom__card-select {
        padding: 1rem;
        border: 2px solid #EAECF0;
        border-radius: 8px;
        width: 100%;
        cursor: pointer;
        user-select: none;
        &.active {
            pointer-events: none;
            cursor: default;
            border-color: var(--second-color);
            background-color: rgba($second-color, 0.025);
            .content__head span {
                border-color: var(--second-color);
                &::before {
                    transform: scale(1);
                }
            }
        }
        .content__head {
            width: 100%;
            display: grid;
            grid-template-columns: minmax(0,20px) minmax(0,1fr);
            gap: 10px;
            align-items: center;
            span {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 2px solid #EAECF0;
                position: relative;
                &::before {
                    content: "";
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: var(--second-color);
                    transition: all ease-in-out 0.2s;
                    transform: scale(0);
                    transform-origin: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            .title__card {
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                margin: 0;
            }
        }
        .content__desc {
            padding: 1rem 0;
            .text__card {
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }
        }
    }

    .custom__mat-select {
        .mat-mdc-select-trigger {
            border-color: #F1F1F1;
            // color: #fff;
        }
    }
    .profile__settings {
        background-color: #fff;
        .title__ps, .current__profile-ps {
            color: var(--second-color);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 1.75rem;
            font-family: var(--font-title);
        }
    }
    .process_cart {
        background-color: #fff;
        .title__pc, .subtitle__pc {
            color: var(--second-color);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 1.75rem;
            font-family: var(--font-title);
        }
        .card__pc {
            border-radius: 0.75rem;
        }
        .order__pc {
            border: 1px solid #e5e7eb;
            border-radius: 0.75rem;
            color: var(--second-color);
            table {
                th {
                    background-color: #f9fafb;
                    border-bottom: 1px solid #e5e7eb;
                }
                td {
                    border-bottom: 1px solid #e5e7eb;
                }
            }
        }
        .order-total__pc {
            border-radius: 0.75rem;
            color: var(--second-color);
            background-color: #EAECF0;
            font-weight: 500;
        }
        .btn__pay {
            background-color: var(--second-color);
            color: #fff;
        }
        .btn__pc {
            background-color: var(--second-color);
            color: #fff;
        }
    }
    .content__pcs {
        width: 1024px;
        display: block;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        overflow: hidden;
        max-width: 100%;
        background: #fff;
        padding: 1rem 1.5rem;
        .content__step-pcs {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            &.active__step {
                .icon__step-pcs {
                    .ctt__icon-pcs {
                        background-color: var(--second-color);
                    }
                }
                .title__pcs {
                    color: var(--primary-color);
                }
            }
            .icon__step-pcs {
                .ctt__icon-pcs {
                    background-color: #fff;
                    box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.102);
                    margin-bottom: 10px;
                }
            }
            .title__pcs {
                font-family: var(--font-title);
                text-transform: uppercase;
                color: var(--second-color);
            }
        }
    }

    .payments {
        background-color: #fff;
        .title__payments {
            color: var(--second-color);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.125rem;
            font-family: var(--font-title);
        }
    }

    .card-general {
        .btns__card-account {
            text-align: left;
        }
    }

    .register__profile {
		background-color: #fff;
	}
    

    // Responsive
    @media (min-width: 768px) {
        .content__pcs {
            justify-content: space-between;
        }
    }
}